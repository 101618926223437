import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import {
  QuickLinkButton,
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const MasteringPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Mastering Music | Jimmy Ether"
        ogDescription="My philospohy on audio mastering for music and how I became a mastering engineer."
        image="https://jimmyether.com/images/mastering-music.jpg"
        url="https://jimmyether.com/mastering-music/"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Mastering Music</h1>
            <p>
              Do you need professional music mastering for your release? If you take your music seriously, then yes. It’s an important final step in music production, particularly if you are putting out more than a single. When mixing, we focus on balancing all elements in the song for a particular effect. In mastering, the point is to balance the sound of the entire release. That includes sequencing, pacing, adding crossfades between songs, managing tonal characteristics with EQ, controlling dynamics with compression, and targeting perceived loudness with limiting. And from my perspective, these final touches should sound good on every system it's played. The highest fidelity system and the poorest quality earbuds.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/mastering-music.jpg" alt="Mastering Music" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>My Trip Down The Mastering Rabbit Hole</h2>
            <p>
              I got into audio mastering partly out of curiosity and partly out of pure frustration. I wanted to understand why — no matter how good the mix — records often came out sounding inferior without mastering. I also wanted to understand why some records were sterile and lifeless after mastering. I sat in on sessions with many well-respected mastering engineers throughout the southeast. A couple of personal heroes in fact. But, I’d always come away from the sessions unhappy with the results. And it was through no fault of the engineers or studios. There was a level of detail I was hoping to reach, and I was unable to articulate exactly what I was after. 
            </p>
            <p>
              Were my mixes so inferior that the mastering engineer could do no better with them? No. I’d heard some horrible mixes come out of mastering sounding wonderful. This all perplexed me for years. So, as is my pattern, I threw myself into that which was baffling and confounding me. Down another rabbit hole. This one has lasted a couple of decades. ￼
            </p>
            <p>
              Many people like to apply a mystical aura around mastering. Even some mastering engineers. But it’s a craft like any other. There’s no magic - just technique and experience. But it is a different skill from tracking and mixing. You have to learn to listen to and think about audio in a completely different context than other parts of the production process.
            </p>
            <p>
              Recording is about capturing a performance. Or, if you are building a track, it's about orchestrating all the elements. Mixing is balancing and presenting the recorded material in a way that draws the listener in. In both tracking and mixing, the song is the world you live in. Mastering is a higher-level perspective. Mastering is about the release as a whole and how it relates to the larger musical world. 
            </p>
            <p>
              I often hear mix engineers malign mastering services as unnecessary and destructive. They don't want to trust someone else to alter the sound they've worked to create. I get that. That's why it's important to build a relationship with a mastering engineer you respect and trust. It's important to understand what the mastering engineer can affect. And you have to communicate what you want. I even recommend sitting in on the mastering session.
            </p>
            <p>
              I'll harp on this in other posts and videos, but mastering is not about loudness. You can provide loudness specifications to a mastering engineer. But you better understand the ramifications. Arbitrary loudness targets have been a cancer on the audio field since the 1990's. Decisions passed down from marketing and promotion people who don't understand sound. Call me crazy, but quality sound 
            </p>
            <h2>Great Mixes Need Mastering</h2>
            <p>
              When you have a great stereo mix of a song, it has the dynamic range and frequency balance to be molded in many different ways. Perfect mixes are rare, but they do happen. So, let’s assume you are a golden-eared Bruce Dickinson who has produced 12 songs, each perfectly mixed. Do they need mastering? Yes. No matter how well they translate alone, as a whole you will hear inconsistencies. Track 3 sounds louder than the rest, even though it’s technically not. Track 7 doesn’t have as much low end as the others. The highs of Track 9 jump out after Track 8.
            </p>
            <p>
              Could you go back to the original mixes and make these adjustments? Yes. And sometimes that's a good idea. But sometimes doing so makes the mix fall apart. Because they were right to begin with. They had the qualities that you were after. They just the mastering process to make them work with the other songs. Mastering allows your mixes to be what they need to be but finds a way to make them work together with the other songs on a commercial release.
            </p>
            <p>
              I don’t know a single good engineer or artist who doesn’t struggle with this issue at some point. Their instincts tell them their mixes are in the sweet spot. But the band are nitpicking issues of consistency as if they are things to be fixed in the mix. The mix engineer is correct - the mixes are right. The client or band is also right - there are consistency issues. The solution is simple. You need a competent mastering engineer. In a day or two those issues will get sorted. Instead of wasting weeks tweaking until you hit the “I’m sick of this. Let’s just say it’s done!” mode. I’ve been there more times than I care to admit as well.
            </p>
            <h2>Cornerstone Tracks in the Mastering Process</h2>
            <p>
              The better your final mix, the more flexibility the mastering engineer has to make the release sound wonderful. A mastering engineer can tweak a dynamic and open mix in countless ways that all sound great. But given any collection of 10 songs, there will be a couple of problem tracks. For some reason, these songs only sound good treated a particular way. I like to start with these tracks. I find the way they sound best. Those songs present characteristics that influence how the less problematic tracks will sound.
            </p>
            <p>
              From there I go back and forth across all the tracks. I'm listening for any tonal or dynamic characteristics that sound out of place. I'll make little tweaks and repeat the process. Ultimately, I end up with a final master that has a well-defined character that is consistent across the entire release. It's the final polish that makes the release a statement and not just a collection of songs.
            </p>
            <h2>Vinyl Vs Digital Distribution</h2>
            <p>
              It's important to note that special consideration has to be taken with a vinyl pre-master. You can't just use the same master that you would submit to streaming services. Vinyl has specific physical constraints that require it to be treated differently. In other articles, I'll cover more about mastering specifically for vinyl.
            </p>
            <h2>Recent Music Production Tutorials, Articles, and Videos</h2>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>LUFS and The Loudness War</h3>
            <p>
              For decades mastering engineers have fought The Loudness Wars. I deep-dive into why it happenend, if there is any merit, if it is actually over, and how I approach masterting today for streaming and HD.
            </p>
            <p>
              <QuickLinkButton
                content={"Read More or Watch Video"}
                url={"/lufs-and-the-loudness-war/"}
                icon={"play circle"}
              />
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/lufs-and-the-loudness-war-high-def.jpg" alt="LUFS and The Loudness War" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default MasteringPage
